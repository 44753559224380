import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'snakeTo',
    standalone: true,
})
export class SnakeToPipe implements PipeTransform {
    /**
     *
     * @param value string to transform
     * @param type 'camel', 'spaced' or 'proper'
     * @returns transformed string
     */
    transform(
        value?: string | number | null,
        type: 'camel' | 'spaced' | 'proper' = 'spaced'
    ): string | null | undefined {
        if (value === null || value === undefined) return value;

        if (type === 'camel') {
            return value.toString().replace(/_([a-z])/g, function (g) {
                return g[1].toUpperCase();
            });
        }
        if (type === 'spaced') {
            return value.toString().replace(/_/g, ' ');
        }
        if (type === 'proper') {
            const withSpaces = value.toString().replace(/_/g, ' ');
            const withCapital =
                withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
            return withCapital;
        }
        return value.toString();
    }
}
